import React, { Component } from "react";
import CustomProduct from "./CustomProduct";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { H_COMPONENTS } from "../../helper/constant";
import { isMobileSafari } from "react-device-detect";

class CustomSlide extends Component {
  state = {
    visibility: false,
    path: this.props.imageThumbPath
  };

  render() {
    const listProducts = this.props.products.map((element, key) => (
      <CustomProduct
        zoom={this.props.zoom}
        disableSwipe={this.props.disableSwipe}
        enableSwipe={this.props.enableSwipe}
        key={key}
        info={element}
        leafletSwipe={this.props.leafletSwipe}
        isSwipeDisabed={this.props.isSwipeDisabed}
        setSwipe={this.props.setSwipe}
      />
    ));

    const safariDetectMobile = isMobileSafari ? 30 : 0;

    return (
      <div className="d-flex p-0 flex-column justify-content-center align-items-center">
        <div style={{ position: "relative" }}>
          <img
            alt=""
            src={
              process.env.PUBLIC_URL +
              this.props.imagePath +
              "?v=" +
              serverResponse.config.version
            }
            className="img-fluid"
            style={{
              maxHeight: serverResponse.config.adsense
                ? `calc(100vh - ${H_COMPONENTS.header +
                    H_COMPONENTS.adsense +
                    H_COMPONENTS.pager +
                    safariDetectMobile}px)`
                : `calc(100vh - ${H_COMPONENTS.header +
                    H_COMPONENTS.pager +
                    safariDetectMobile}px)`
            }}
          />
          {listProducts}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps)(CustomSlide);
