import { isIOS } from "react-device-detect";

export const styles = {
  copyButton: {
    background: "#d94a4a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "360px",
    width: "32px",
    height: "32px"
  },
  wa1: {
    display:
      window.ReactNativeWebView !== undefined ? "none" : "inline"
  },
  wa2: {
    display:
      window.ReactNativeWebView !== undefined ? "inline" : "none"
  }
};
